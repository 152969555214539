.sd-product-grid, .sd-discovery-gnav, .sd-discovery {
  .elc-product-grid {
    &--header {
      background-color: transparent;
      box-shadow: none;
      position: relative;
      z-index: 9;
      border-bottom: 1px solid $color-light-gray;
      flex-wrap: nowrap;
      justify-content: flex-end;
      @media #{$medium-up} {
        margin: 15px 0;
        padding: 5px 0;
        border-top: 1px solid $color-light-gray;
        border-bottom: 0;
      }
      .elc-orderable-filters-wrapper ~ div.elc-sort-wrapper {
        @media #{$medium-up} {
          position: absolute;
          top: 7px;
          #{$rdirection}: 0;
          padding: 0;
        }
        &:after {
          background: url($svg-path + 'dropdown.svg') no-repeat;
          background-size: 100% 100%;
          top: 0;
          bottom: 0;
          content: '';
          display: inline-block;
          height: 10px;
          width: 10px;
          margin: auto 0;
          position: absolute;
          #{$rdirection}: 18px;
          @media #{$medium-up} {
            content: none;
          }
        }
        select {
          @include ternary($text-direction == ltr, direction, rtl, ltr);
          font-size: 12px;
          letter-spacing: 1px;
          background: none;
          color: $color-navy;
          @media #{$medium-up} {
            letter-spacing: 1.25px;
          }
          option {
            direction: $text-direction;
          }
        }
      }
      .elc-orderable-filters-wrapper {
        flex: 1;
        @media #{$medium-up} {
          width: 100%;
        }
        &.filters-applied {
          .elc-product-grid--filter-results-count-label {
            display: flex;
          }
        }
      }
    }
    &--filters-section {
      display: flex;
      @media #{$medium-up} {
        flex-wrap: wrap;
        width: 100%;
      }
    }
    &--filters-modal-button {
      @include swap_direction(padding, 10px 10px 10px 20px);
      background: transparent;
      border: 0;
      color: $color-navy;
      font-weight: normal;
      text-transform: capitalize;
      width: auto;
      display: flex;
      align-items: center;
      height: 40px;
      &:after {
        background: url($svg-path + 'dropdown.svg') no-repeat;
        background-size: 100% 100%;
        content: '';
        display: inline-block;
        height: 10px;
        width: 10px;
        margin-#{$ldirection}: 8px;
        vertical-align: middle;
      }
      &:hover {
        background: none;
      }
      .elc-filter-icon {
        display: none;
      }
      .elc-filters-label {
        @include text--short--plain;
        letter-spacing: 1px;
        word-break: keep-all;
        @media #{$medium-up} {
          letter-spacing: 1.25px;
        }
      }
      .elc-applied-filters-number {
        display: none;
      }
    }
    &--filter-results-count-wrapper {
      padding: 0;
      color: $color-medium-gray;
      text-transform: uppercase;
      font-size: 12px;
    }
    &--filter-results-count-label {
      padding: 10px 0;
      min-width: 78px;
      line-height: 1;
      align-items: center;
      text-transform: none;
      display: none;
      text-align: $ldirection;
      @media #{$medium-up} {
        text-transform: uppercase;
        padding: 0;
      }
    }
  }
}

.sd-product-grid,
.sd-discovery-gnav, .sd-discovery,
.elc-mobile-filters-modal {
  .elc-responsive-modal-mask & {
    text-align: center;
    .elc-overlay-wrapper-close-button {
      #{$rdirection}: -4px;
      background-color: transparent;
      position: absolute;
      top: 10px;
      .elc-remove-icon {
        width: 20px;
        height: 20px;
        transform: scale(1.5);
        background-color: $color-medium-gray;
      }
    }
  }
  .elc-product-grid {
    &--clear-all,
    &--apply-filter {
      border-radius: 0;
      border: 1px solid $color-black;
      height: 40px;
      line-height: 1;
      bottom: 15px;
      #{$ldirection}: 0;
      @media #{$medium-up} {
        height: auto;
      }
      .elc-product-grid--apply-filter-label {
        line-height: 1;
      }
    }
    &--apply-filter {
      @include button--dark;
      width: 80%;
      align-items: center;
      margin: 10px 0 50px;
      &-label {
        color: $color-white;
        font-family: $akzidenz;
      }
      &:hover,
      &:active {
        background: $color-navy;
        color: $color-white;
      }
    }
    &--clear-all {
      @include text--short--plain;
      color: $color-medium-gray;
      background-color: transparent;
      cursor: pointer;
      width: 100%;
      text-decoration: underline;
      border: none;
      margin: 0;
      text-underline-offset: 1px;
      &:hover,
      &:active {
        background: transparent;
        color: $color-medium-gray;
      }
      @media #{$medium-up} {
        border: 0;
        text-decoration: underline;
        width: auto;
        position: relative;
        top: 0;
        padding: 10px 0;
      }
      .elc-icon {
        display: none;
      }
      &:hover,
      &:active {
        background: none;
      }
    }
    &--filter-set {
      @include text--short--plain;
      font-size: 14px;
      align-items: center;
      background: transparent;
      color: $color-navy;
      line-height: 1;
      text-align: #{$ldirection};
      width: 100%;
      padding: 15px 0;
      justify-content: flex-start;
      display: flex;
      &:active,
      &:hover {
        background: transparent;
      }
      @media #{$medium-up} {
        padding: 10px 0;
        display: inline-block;
        font-size: 12px;
      }
      &:after {
        background: url($svg-path + 'plus.svg') no-repeat;
        background-size: 100% 100%;
        content: '';
        height: 11px;
        width: 11px;
        #{$rdirection}: 20px;
        position: absolute;
        @media #{$medium-up} {
          background: url($svg-path + 'dropdown.svg') no-repeat;
          width: 8px;
          height: 8px;
          transition: transform 0.25s;
          display: inline-block;
          margin-#{$ldirection}: 10px;
          #{$rdirection}: auto;
          position: relative;
        }
      }
      &.active {
        @media #{$medium-up} {
          font-weight: bold;
        }
        &:after {
          background: url($svg-path + 'minus.svg') no-repeat;
          #{$rdirection}: 20px;
          position: absolute;
          @media #{$medium-up} {
            background: url($svg-path + 'dropdown.svg') no-repeat;
            #{$rdirection}: auto;
            position: relative;
            transform: rotate(180deg);
            margin-#{$ldirection}: 10px;
          }
        }
      }
      &-wrapper {
        display: inline-block;
        width: 100%;
        @media #{$medium-up} {
          padding-#{$rdirection}: 30px;
          width: unset;
        }
        &:last-of-type {
          @media #{$medium-up} {
            padding: 0;
          }
          .elc-product-grid--filter-option-checkbox:last-child {
            margin-bottom: 0;
          }
        }
        &:first-of-type {
          @media #{$medium-up} {
            padding-#{$ldirection}: 0;
          }
        }
      }
    }
    &--filter-options-wrapper {
      padding: 10px 0;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      @media #{$medium-up} {
        margin: 0;
        padding: 15px;
        display: block;
        width: auto;
        position: absolute;
        background: $color-white;
        top: 30px;
      }
    }
    &--filter-option-checkbox {
      margin-bottom: 5px;
      width: 100%;
      @media #{$medium-up} {
        width: 25%;
        height: 32px;
        margin: 0 0 17px;
      }
      &.no-results {
        cursor: default;
        background: none;
        padding: 0;
        .elc-checkbox-icon {
          cursor: default;
        }
      }
      .elc-checkbox {
        display: flex;
        padding: 5px 0;
        &-label {
          line-height: 1.5;
          margin: 0;
          padding: 0;
          height: 20px;
          text-transform: none;
          font-family: $akzidenz;
        }
        &-check-area {
          background-color: $color-white;
          display: inline-flex;
          height: 20px;
          margin-#{$rdirection}: 17px;
          overflow: hidden;
          top: unset;
          width: 20px;
          @media #{$medium-up} {
            height: 20px;
            margin-#{$rdirection}: 10px;
          }
        }
        .elc-checkbox-icon {
          background-size: 100% 100%;
          background-color: transparent;
          content: '';
          display: inline-block;
          height: 12px;
          margin: auto 0;
          -webkit-mask: unset;
        }
        &-not-selected {
          .elc-checkbox-icon {
            background: url($svg-path + 'checkbox.svg') no-repeat;
            background-size: 24px 12px;
          }
        }
        &-selected {
          .elc-checkbox-icon {
            background: url($svg-path + 'checkbox-checked.svg') no-repeat;
            background-size: 24px 12px;
          }
        }
      }
    }
  }
  .elc-filter-by-label {
    width: 100%;
    display: inline-block;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1.25px;
    font-weight: bold;
    text-align: $ldirection;
    padding: 10px 0 20px;
    @media #{$medium-up} {
      @include swap_direction(padding, 10px 30px 10px 0);
      font-size: 12px;
      height: auto;
      width: unset;
      font-weight: normal;
      line-height: 1;
    }
  }
  .elc-styled-filters-active-filters-no {
    padding-#{$ldirection}: 10px;
    @media #{$medium-up} {
      padding: 0;
    }
  }
}

// Styles for mobile overlay
.elc-responsive-modal-mask {
  .elc-mobile-filters-modal {
    #{$ldirection}: 0;
    font-size: 14px;
    background: $color-white;
    height: 100%;
    overflow-x: hidden;
    top: 0;
    transform: none;
  }
}
